<template>
    <div class="condition_chunk">
        <div 
            class="function_group radio"
            v-for="(item, index) in radioElement" 
            :key="index"
        >
            <div class="radiobox_wrap">
                <input
                    type="radio" 
                    :id="item.id"
                    :value="item.id"
                    :name="radioGroupName"
                    :checked="index === selectedRadioIndex"
                    @change="watchCheck(index)"
                >
                <label :for="item.id">
                    <div class="icon radio_unactive" :class="{ checked: index === selectedRadioIndex }" />
                    <span>{{ item.label }}</span>
                </label>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'RadioGroupComp',
    data() {
        return {
            radioValue: null,
        }
    },
    props: {
        radioElement: {
            type: Array,
            default: () => [],
        },
        radioGroupName: {},
        selectedRadioIndex: {},
    },
    methods: {
        watchCheck(index) {
            this.$emit('watchCheck', index)
        }
    }
}
</script>
<style lang="">
    
</style>