import axios from 'axios'

async function login({ id, password }) {
    try {
        return axios.post(`/api/v1/login`, {
            id,
            password,
        })
    } catch (error) {
        console.log(error)
    }
}

export {
    login,
}