<template>
    <article class="default_table" :class="{select_possible:selectTable}">
        <div class="entire_count_wrap">
            <slot name="entireCount"></slot>
        </div>
        <table>
            <thead>
            <tr>
                <th v-for="(column, index) in columns" :key="index">{{ column.label }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(row, rowIndex) in data" :key="rowIndex">
                <td v-for="(column, colIndex) in columns" :key="colIndex" @click="showDetailPage(row)">
                    {{ column.key.includes('Date') ? formatContractDate(row[column.key]) : formatSolvedStatus(row[column.key]) }}
                </td>
            </tr>
            </tbody>
        </table>
    </article>
</template>

<script>

export default {
    name: 'TableComp',
    props: {
        columns: {
            type: Array,
            default: () => [],
        },
        data: {
            type: Array,
            default: () => [],
        },
        selectTable: {},
    },
    methods: {
        showDetailPage(data) {
            this.$emit('openModal', {
                    contractNo: data.contractNo,
                    documentNo: data.documentNo,
                    bizCode: data.bizCode
                }
            );
        },
        formatContractDate(value) {
            if (value === null || value === undefined) {
                return ''
            }
            return value.replace('T', ' ')
        },
        formatSolvedStatus(value) {
            if (typeof value === 'boolean') {
                return value ? '해결' : '미해결'
            }
            return value || ''
        },
    },
}
</script>