<template>
    <div class="modal_wrap">
        <div class="backdrop" @click="closeModal"></div>
        <div class="modal_content_wrap">
            <button><div class="icon cancel_btn" @click="closeModal"></div></button>

            <div class="modal_header">
                <div class="modal_title"><slot name="modalTitleSocket">default</slot></div>
                <div class="modal_detail_comment">
                    <slot name="modalDetailComment"></slot>
                </div>
            </div>

            <div class="modal_content"><slot name="modalTableSocket"></slot></div>

            <div class="modal_footer"><slot name="buttonSocket"></slot></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalComp',
    methods: {
        closeModal() {
            this.$emit('close')
        }
    },
}
</script>