import Vue from "vue";
import VueRouter from "vue-router";

import { store } from "@/stores";

import LoginView from "@/views/LoginView.vue";
import MainView from "@/views/MainView.vue";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    // base: process.env.BASE_URL,
    routes: [
        {
            path: "/",
            name: "LoginView",
            component: LoginView,
            beforeEnter: async (to, from, next) => {
                localStorage.removeItem('vuex')
                next()
            }
        },
        {
            path: "/main",
            name: "MainView",
            component: MainView,
            beforeEnter: async (to, from, next) => {
                await store.dispatch('CommonEbaStore/FETCH_BIZ_INFORMATIONS').then(response => {
                    if (response.data.result === true) {
                        next()
                    }
                })
            },
            children: [
                {
                    path: "EBA01MC",
                    name: "EBA01MC",
                    components: {
                        mainContentRouterView: () => import('@/views/mainContent/EBA01MC.vue'),
                    },
                },
                {
                    path: "EBA02MC",
                    name: "EBA02MC",
                    components: {
                        mainContentRouterView: () => import('@/views/mainContent/EBA02MC.vue'),
                    },
                    beforeEnter: async (to, from, next) => {
                        await store.dispatch('CommonEbaStore/FETCH_CONTRACT_TRACKING').then(response => {
                            if (response.data.result === true) {
                                next()
                            }
                        })
                    }
                },
                {
                    path: "EBA03MC",
                    name: "EBA03MC",
                    components: {
                        mainContentRouterView: () => import('@/views/mainContent/EBA03MC.vue'),
                    },
                    beforeEnter: async (to, from, next) => {
                        await store.dispatch('CommonEbaStore/FETCH_CONTRACT_STATUS').then(response => {
                            if (response.data.result === true) {
                                next()
                            }
                        })
                    }
                },
                {
                    path: "EBA04MC",
                    name: "EBA04MC",
                    components: {
                        mainContentRouterView: () => import('@/views/mainContent/EBA04MC.vue'),
                    },
                    beforeEnter: async (to, from, next) => {
                        await store.dispatch('CommonEbaStore/FETCH_CONTRACT_FAILED').then(response => {
                            if (response.data.result === true) {
                                next()
                            }
                        })
                    }
                },
                {
                    path: "EBA05MC",
                    name: "EBA05MC",
                    components: {
                        mainContentRouterView: () => import('@/views/mainContent/EBA05MC.vue'),
                    },
                    beforeEnter: async (to, from, next) => {
                        await store.dispatch('CommonEbaStore/FETCH_CONTRACT_ECMS_STATISTICS', {dateType: 'all'}).then(response => {
                            if (response.data.result === true) {
                                next()
                            }
                        })
                    }
                },
                {
                    path: "EBA06MC",
                    name: "EBA06MC",
                    components: {
                        mainContentRouterView: () => import('@/views/mainContent/EBA06MC.vue'),
                    },
                    beforeEnter: async (to, from, next) => {
                        await store.dispatch('CommonEbaStore/FETCH_CONTRACT_STATISTICS_CALCULATE').then(response => {
                            if (response.data.result === true) {
                                next()
                            }
                        })
                    }
                },
                {
                    path: "EBA07MC",
                    name: "EBA07MC",
                    components: {
                        mainContentRouterView: () => import('@/views/mainContent/EBA07MC.vue'),
                    },
                    beforeEnter: async (to, from, next) => {
                        await store.dispatch('CommonEbaStore/FETCH_CONTRACT_STATISTICS').then(response => {
                            if (response.data.result === true) {
                                next()
                            }
                        })
                    }
                },
            ],
        },
    ]
});

export default router;
