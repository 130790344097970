<template>
    <div class="datepicker_wrap">
        <label for="datePicker" class=""><slot name="datepickerLabel"></slot></label>
        <datepicker
            :placeholder="state[0].date | formatNumberDate | formatHyphenDatePickerContractDate"
            :language="ko"
            :format="format"
            :disabled-dates="startDateDisabled"
            :selected="StartDateData()"
            :highlighted="highlighted"
            v-model="startDateValue"
            @input="updateHighlighted('start')"
        />
        <span class="hyphen"></span>
        <datepicker
            :placeholder="state[0].date | formatNumberDate | formatHyphenDatePickerContractDate"
            :language="ko"
            :format="format"
            :disabled-dates="endDateDisabled"
            :selected="EndDateData()"
            :highlighted="highlighted"
            v-model="endDateValue"
            @input="updateHighlighted('end')"
        />
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import {ko} from 'vuejs-datepicker/dist/locale'
import { startDateFormat, endDateFormat } from '@/assets/js/common'

export default {
    name: 'DatepickerComp',
    data() {
        return {
            format: 'yyyy.MM.dd',
            startDateValue: null,
            endDateValue: new Date(),
            startDateDisabled: {
                from: new Date(),
                to: null,
            },
            endDateDisabled: {
                from: new Date(),
                to: null,
            },
            highlighted: {
                from: null,
                to: null,
            },
            ko: ko,
            state: [
                {
                    date: new Date
                },
            ],
        }
    },
    components: {
        Datepicker,
    },
    methods: {
        StartDateData() {
            if (this.endDateValue !== null) {
                this.startDateDisabled.from = this.endDateValue
                if (this.endDateValue >= this.startDateValue) {
                    this.$emit('sendConditionSearchPeriod', this.startDateValue)
                    this.fetchList()
                }
            }
        },
        EndDateData() {
            if (this.startDateValue !== null) {
                this.endDateDisabled.to = this.startDateValue
                if (this.endDateValue >= this.startDateValue) {
                    this.$emit('sendConditionSearchPeriod', this.endDateValue)
                    this.fetchList()
                }
            }
        },
        updateHighlighted(type) {
            if (type === 'start') {
                this.highlighted.from = this.startDateValue
                this.highlighted.to = this.endDateValue
            } else if (type === 'end') {
                this.highlighted.from = this.startDateValue
                this.highlighted.to = this.endDateValue
            }
        },
        fetchList() {
            if (this.startDateValue && this.endDateValue) {
                this.commonEbaStore.searchType.startDate = startDateFormat(this.startDateValue);
                this.commonEbaStore.searchType.endDate = endDateFormat(this.endDateValue);
            }
        },
    }
}
</script>

<style scoped>

</style>