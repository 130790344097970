<template>
    <div class="container">
        <main class="login">
            <div class="content_wrap">
                <section class="content">
                    <article class="login_title">
                        <div class="login_title_wrap">
                            <div class="title_top_img"></div>
                            <div class="title_bottom_img"></div>
                        </div>
                        <div data-divide="line"></div>
                        <div class="login_logo_img"></div>
                    </article>
                    <article class="login_input_field">
                        <input type="text" placeholder="ID" ref="id" class="type_id" v-model="id"
                               @keyup.enter="login()">
                        <input type="password" placeholder="PASSWORD" class="type_password" v-model="password"
                               @keyup.enter="login()">
                        <div class="wrong_comment" v-if="failed_login">아이디 또는 비밀번호가 잘못되었습니다.</div>
                    </article>
                    <article class="login_btn_field">
                        <button class="login_btn" @click="login()">로그인</button>
                    </article>
                </section>
            </div>
        </main>
    </div>
</template>

<script>
import { login } from "@/api/common/login";
import { saveAuthToken } from "@/utils/token";

export default {
    name: "LoginView",
    data() {
        return {
            id: "",
            password: "",
            failed_login: false
        };
    },
    methods: {
        async login() {
            try {
                const response = await this.tryLogin();
                if (response.data.result === true) {
                    await this.handleSuccessfulLogin(response.headers.authorization);
                } else {
                    this.handleFailedLogin();
                }
            } catch (error) {
                this.handleLoginError(error);
            }
        },
        async tryLogin() {
            return await login({ id: this.id, password: this.password });
        },
        async handleSuccessfulLogin(authorizationHeader) {
            await saveAuthToken(authorizationHeader);
            this.failed_login = false;
            await this.$router.push({ name: "EBA01MC" });
        },
        handleFailedLogin() {
            this.id = "";
            this.password = "";
            this.failed_login = true;
            this.$refs.id.focus();
        },
        handleLoginError(error) {
            console.error(error);
        }
    }
};
</script>