import { getAuthFromToken } from "@/utils/token";
import authorizationStore from "@/stores/modules/authorizationStore";

export function setInterceptors(instance) {
    instance.interceptors.request.use(
        function(config) {
            config.headers.Authorization = getAuthFromToken();
            return config
        },
        function(error) {
            return Promise.reject(error)
        }
    );

    instance.interceptors.response.use(
        async function(response) {
            if (response.data.result) {
                return response;
            } else {
                authorizationStore.state.token = ''
                location.replace("/")
                return response;
            }
        },
        function(error) {
            return Promise.reject(error)
        }
    );
    return instance
}