<template>
    <Pie
        id="pie_chart"
        :options="chartOptions"
        :data="chartData"
        :style="customPieStyle"
    />
</template>

<script>
import { Pie } from "vue-chartjs";

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
    name: "PieChartComp",
    components: { Pie },
    props: {
        data: {
            type: Object,
            default: null,
        }
    },
    created() {
        this.countDataBinding()
    },
    computed: {
        customPieStyle() {
            return {
                width: `${600}px`,
                height: `${260}px`
            };
        }
    },
    watch: {
        statisticsCalculate: {
            handler: "countDataBinding",
            deep: true
        }
    },
    data() {
        return {
            chartData: {
                labels: ["SMS", "전자서명", "PDF"],
                datasets: [
                    {
                        backgroundColor: ["#AEC9E0", "#F2B2A8", "#D0BEC4"],
                        data: [40, 40, 40]
                    }
                ]
            },
            chartOptions: {
                responsive: false,
                maintainAspectRatio: false
            },
            count: [],
        };
    },
    methods: {
        countDataBinding() {
            //TODO: 데이터는 동적으로 들어오는데 왜 chart는 변하지 않는 것인지 확인 필요.
            this.chartData.datasets[0].data[0] = this.data.smsCount;
            this.chartData.datasets[0].data[1] = this.data.signatureCount;
            this.chartData.datasets[0].data[2] = this.data.pdfCount;
        }
    }
};
</script>
