<template>
    <div>
        <header class="header">
            <h1 class="header_title">채권전자계약 관리자시스템</h1>
            <button class="icon user_info" @click="openUserInfoComp()"></button>
        </header>
        <UserInfoComp v-if="showModal" :class="{ 'active': showModal, 'unactive': !showModal }" :showModal="showModal" @close="closeUserInfoComp()"/>
    </div>
</template>

<script>
export default {
    name: 'HeaderComp',
    data() {
        return {
            showModal: false,
        }
    },
    methods: {
        openUserInfoComp() {
            this.showModal = true
        },
        closeUserInfoComp() {
            this.showModal = false
        },
    },
}
</script>