const todayDateFormat = () => {
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    if (month < 10) {month = '0' + month;}
    if (day < 10) {day = '0' + day;}
    if (hours < 10) {hours = '0' + hours;}
    if (minutes < 10) {minutes = '0' + minutes;}
    if (seconds < 10) {seconds = '0' + seconds;}
    return year + month + day + hours + minutes + seconds
}

const startDateFormat = (time) => {
    const TIME_ZONE = 3240 * 10000
    const date = new Date(time.getFullYear(), time.getMonth(), time.getDate(), 0, 0, 1, 0)
    return new Date(+date + TIME_ZONE).toISOString().replace('T', ' ').replace(/\..*/, '')
}
const endDateFormat = (time) => {
    const TIME_ZONE = 3240 * 10000
    const date = new Date(time.getFullYear(), time.getMonth(), time.getDate(), 23, 59, 59, 0)
    return new Date(+ date + TIME_ZONE).toISOString().replace('T', ' ').replace(/\..*/, '')
}

const firstDay = () => {
    const date = new Date();
    const first = new Date(date.getFullYear(), date.getMonth(), 1);
    return startDateFormat(first);
}

const lastDay = () => {
    const date = new Date();
    const last = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return endDateFormat(last);
}

const changedDate = (applyDate) => {
    const TIME_ZONE = 3240 * 10000
    const date = new Date(applyDate)
    return new Date(+ date + TIME_ZONE).toISOString().replace('T', ' ').replace(/\..*/, '')
}



export {todayDateFormat, startDateFormat, endDateFormat, firstDay, lastDay, changedDate}