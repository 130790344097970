<template>
    <aside class="SNB" @mouseenter="depthHover()" @mouseleave="depthBlur()" >
        <div class="icon menuPicto"></div>
        <div data-divide="line"></div>
        <div v-for="(item, index) in depthList" :key="item.id">
            <button data-depth="first" @click="depthOpen(index)">
                <div class="icon" :class="item.iconClass" />
                <div class="depth_1_wrap">
                    <span class="depth_1_title" :class="{'show' : depthHoverValue}">{{ item.firstDepth }}</span>
                </div>
            </button>
            <ul data-depth="second" :class="{ 'accordion_open': item.open }" v-if="item.open">
                <li v-for="(subItem, index) in item.secondDepth" :key="index">
                    <button @click="secondDepthOpen(subItem)">{{ subItem.category }}</button>
                </li>
            </ul>
        </div>
    </aside>
</template>

<script>

export default {
    name: 'SNBComp',
    data() {
        return {
            depthList: [
                { id: 1, animationName: 'first', iconClass: 'institute', firstDepth: '기관관리', open: false, routeName: 'EBA01MC', secondDepth: [] },
                { id: 2, animationName: 'second', iconClass: 'history', firstDepth: '이력관리', open: false, routeName: '', secondDepth: [{category: '계약현황조회', routeName: 'EBA02MC'}, {category: '전자계약 전송 현황', routeName: 'EBA03MC'}, {category: '오류이력조회', routeName: 'EBA04MC'}] },
                { id: 3, animationName: 'third', iconClass: 'stats', firstDepth: '통계관리', open: false, routeName: '', secondDepth: [{category: '계약통계', routeName: 'EBA05MC'}, {category: '정산통계', routeName: 'EBA06MC'}] },
                { id: 4, animationName: 'fourth', iconClass: 'calculate', firstDepth: '정산관리', open: false, routeName: 'EBA07MC', secondDepth: [] },
            ],
            depthHoverValue: false,
        }
    },
    methods: {
        depthOpen(index) {
            this.depthList.forEach((item, i) => {
                if (i === index) {
                    if (item.secondDepth.length !== 0) {
                        item.open = !item.open
                    } else if (item.secondDepth.length === 0 && item.routeName !== '' && this.$route.name !== item.routeName) {
                        this.$router.push({ name: `${item.routeName}` })
                    } else {
                        item.open = false
                    }
                } else {
                    item.open = false
                }
            });
        },
        secondDepthOpen(subItem) {
            // prevent duplicate router calls
            if (this.$route.name !== `${subItem.routeName}`) {
                this.$router.push({name: `${subItem.routeName}`});
            }
        },
        depthHover() {
            this.depthHoverValue = true
        },
        depthBlur() {
            this.depthList.forEach((item) => {
                this.depthHoverValue = false
                item.open === true ? item.open = false : ''
            })
        },
    }
}
</script>