import Vue from "vue";
import Vuex from "vuex";
import CommonEbaStore from '@/stores/modules/commonEbaStore'
import AuthorizationStore from "@/stores/modules/authorizationStore"

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        CommonEbaStore,
        AuthorizationStore,
    },
    plugins: [createPersistedState({
        paths: ['AuthorizationStore']
    })]
});