import {instanceWithAuth} from "@/api";

async function getBizInformations() {
    try {
        return await instanceWithAuth.get(`/api/v1/biz/informations`)
    } catch (error) {
        console.log(error);
    }
}

export {
    getBizInformations
}