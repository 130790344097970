<template>
    <article class="pagination_wrap" v-cloak>
        <pagination 
            :records="commonEbaStore.pageRequest.totalElements"
            v-model="page"
            :per-page="perPage"
            @paginate="callback"
            :options="options"
        />
    </article>
</template>

<script>
import Pagination from 'vue-pagination-2';

export default {
    name: 'PaginationComp',
    components: {
       Pagination
    },
    data() {
        return {
            page: 1, // 마운트시에 보여질 첫 페이지 위치
            perPage: 10, // 페이지당 데이터 수
            records: [], // 레코드 수
            options: {
                chunksNavigation: "fixed",
                edgeNavigation: false,
                chunk: 10, // 페이지네이션 청크 수
                texts: {
                    count: "{page}/{pages}",
                    nextPage: false,
                    nextChunk: false,
                    prevPage: false,
                    prevChunk: false,
                }
            },
        }
    },
    computed: {
        totalElements() {
            return this.commonEbaStore.pageRequest.totalElements
        },
    },
    methods: {
        callback(page) {
            this.commonEbaStore.pageRequest.page = page
            this.$nextTick(() => {
                this.$emit('response');
            });
        },
    },
}
</script>

<style scoped>
[v-cloak] {
    display: none;
}
</style>
