import { store } from "@/stores";

async function saveAuthToken(value) {
    store.commit('AuthorizationStore/AUTHORIZATION_TOKEN', `Bearer ${value}`)
}

function getAuthFromToken() {
    return store.getters['AuthorizationStore/TOKEN']
}

export {
    saveAuthToken,
    getAuthFromToken,
}