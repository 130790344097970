<template>
    <div class="container">
        <main class="main">
            <div class="content_wrap">
                <section class="content">
                    <HeaderComp />
                    <SNBComp />
                    <div class="main_content_wrap">
                        <div class="main_content">
                            <router-view name="mainContentRouterView" />
                        </div>
                    </div>
                </section>
            </div>
        </main>
    </div>
</template>

<script>

export default {
    name: 'MainView',
}
</script>