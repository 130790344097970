<template>
    <div class="user_info_wrap">
        <div class="backdrop" @click="close()" />
        <aside class="aside user_info">
            <button class="icon cancel_btn" @click="close()"></button>
            <div class="user_info_content">
                <div class="icon userPicto"></div>
                <div class="welcome_user">
                    환영합니다!<br/>
                    <span data-id="idName">{{ 'honggd1004' }}</span>님
                </div>
                <div class="remain_time">남은시간 {{ '29:37' }}</div>
                <button class="primary_btn logout_btn" @click="logout()">
                    <div class="icon logoutPicto"></div>
                    <div>로그아웃</div>
                </button>
            </div>
        </aside>
    </div>
</template>

<script>
import authorizationStore from "@/stores/modules/authorizationStore";

export default {
    name: 'UserInfoComp',
    props: {
        showModal: Boolean,
    },
    methods: {
        close() {
            this.$emit('close')
        },
        logout() {
            authorizationStore.state.token = ''
            this.$router.replace({name: 'LoginView'})
        }
    },
}
</script>