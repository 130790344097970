<template>
    <article class="main_content_header">
        <h1 class="content_title"><slot name="contentTitle">default</slot></h1>
        <div class="breadcrumb">
            <slot name="breadcrumb"></slot>
        </div>
    </article>
</template>

<script>

export default {
    name: 'MainContentHeaderComp',
}
</script>