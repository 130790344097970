import Vue from "vue"

import BarChartComp from "@/components/common/BarChartComp.vue"
import ChartTableComp from "@/components/common/ChartTableComp.vue"
import PieChartComp from "@/components/common/PieChartComp.vue"
import DatepickerComp from "@/components/common/DatepickerComp.vue"
import MainContentHeaderComp from "@/components/common/MainContentHeaderComp.vue"
import ModalTableComp from "@/components/common/ModalTableComp.vue"
import TableComp from "@/components/common/TableComp.vue"
import RadioGroupComp from "@/components/common/RadioGroupComp.vue"
import SearchConditionComp from "@/components/common/SearchConditionComp.vue"
import PaginationComp from "@/components/common/PaginationComp.vue"

import SNBComp from "@/components/SNBComp.vue"
import UserInfoComp from "@/components/UserInfoComp.vue"
import ModalComp from "@/components/ModalComp.vue"
import HeaderComp from "@/components/HeaderComp.vue"

// common Components
Vue.component("BarChartComp",BarChartComp)
Vue.component("ChartTableComp",ChartTableComp)
Vue.component("PieChartComp",PieChartComp)
Vue.component("DatepickerComp",DatepickerComp)
Vue.component("MainContentHeaderComp",MainContentHeaderComp)
Vue.component("ModalComp",ModalComp)
Vue.component("PaginationComp",PaginationComp)
Vue.component("RadioGroupComp",RadioGroupComp)
Vue.component("SearchConditionComp",SearchConditionComp)
Vue.component("TableComp",TableComp)

// substance Components
Vue.component("HeaderComp",HeaderComp)
Vue.component("ModalTableComp",ModalTableComp)
Vue.component("SNBComp",SNBComp)
Vue.component("UserInfoComp",UserInfoComp)

export default {
  name: 'GlobalComponents'
}