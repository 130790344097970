import {instanceWithAuth} from "@/api";

async function getContractTracking() {
    try {
        return await instanceWithAuth.get(`/api/v1/contract/tracking`, {
            params: {
                dateType: 'all',
                trackingType: 'all',
                page: 1
            }
        })
    } catch (error) {
        console.log(error);
    }
}

async function getContractStatus() {
    try {
        return await instanceWithAuth.get(`/api/v1/contract/status`, {
            params: {
                dateType: 'all',
                page: 1
            }
        })
    } catch (error) {
        console.log(error)
    }
}

async function getContractStatistics() {
    try {
        return await instanceWithAuth.get(`/api/v1/contract/statistics`, {
            params: {
                dateType: 'all',
                page: 1
            }
        })
    } catch (error) {
        console.log(error)
    }
}

async function getContractStatusDetails(data) {
    try {
        return await instanceWithAuth.post(`/api/v1/contract/status/details`, {
                contractNo: data.contractNo,
                documentNo: data.documentNo,
                bizCode: data.bizCode
            }
        )
    } catch (error) {
        console.log(error)
    }
}

async function getContractStatisticsDetails(data) {
    try {
        return await instanceWithAuth.get(`/api/v1/contract/statistics/detail`, {
            params: {
                contractNo: data.contractNo,
                documentNo: data.documentNo,
                bizCode: data.bizCode,
            }
        })
    } catch (error) {
        console.log(error)
    }
}

async function getContractStatisticsCalculate() {
    try {
        return await instanceWithAuth.get(`/api/v1/contract/statistics/calculate`, {
            params: {
                dateType: 'all'
            }
        })
    } catch (error) {
        console.log(error)
    }
}

async function getContractFailed() {
    try {
        return await instanceWithAuth.get(`/api/v1/contract/failed`, {
            params: {
                dateType: 'all',
                page: 1
            }
        })
    } catch (error) {
        console.log(error)
    }
}

async function getContractEcmsStatistics(data) {
    try {
        return await instanceWithAuth.get(`/api/v1/ecms/statistics`, {
            params: {
                dateType: data.dateType,
                startDate: data.startDate === null ? null : data.startDate,
                endDate: data.endDate === null ? null : data.endDate,
            }
        })
    } catch (error) {
        console.log(error)
    }
}


export {
    getContractTracking,
    getContractStatus,
    getContractStatistics,
    getContractStatusDetails,
    getContractStatisticsDetails,
    getContractStatisticsCalculate,
    getContractFailed,
    getContractEcmsStatistics,
}