<template>
    <Bar 
        id="bar_chart"
        :data="chartData"
        :options="options || {}"
        :style="customBarStyle"
    />
</template>
<script>
import { Bar } from 'vue-chartjs'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
    name: 'BarChartComp',
    components: { Bar },
    created() {
        this.propsData = this.data
    },
    data() {
        return {
            propsData: null,
        }
    },
    computed: {
        customBarStyle() {
            return {
                width: `${600}px`,
                height: `${260}px`,
            }
        },
        chartData() {
            return this.propsData
        },
        chartOptions() {
            return this.chartOptions
        },
    },
    watch: {
        data: {
            handler: "dataBinding",
            deep: true,
        },
    },
    props: {
        data: {
            type: Object,
            required: true,
        },
        options: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        dataBinding() {
            this.propsData = this.data
        }
    }
}
</script>
<style>

</style>