<template>
    <article class="search_condition_wrap">
        <slot name="conditionList"></slot>
    </article>
</template>

<script>
export default {
    name: 'SearchConditionComp',
}
</script>