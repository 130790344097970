<template>
    <article class="default_table chart">
        <table>
            <thead>
                <tr>
                    <th v-for="(column, index) in columns" :key="index">{{ column.label }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, rowIndex) in data" :key="rowIndex">
                    <td v-for="(column, colIndex) in columns" :key="colIndex" @click="showDetailPage">{{ row[column.key] }}건</td>
                </tr>
            </tbody>
        </table>
    </article>
</template>

<script>
export default {
    name: 'TableComp',
    props: {
        columns: {
            type: Array,
            default: () => [],
        },
        data: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        showDetailPage() {
            this.$emit('openModal')
        },
    },
}
</script>