const authorizationStore = {
    namespaced: true,
    state: {
        token: '',
    },
    getters: {
        TOKEN(state) {
            return state.token
        }
    },
    mutations: {
        AUTHORIZATION_TOKEN(state, value) {
            state.token = value
        }
    }
}

export default authorizationStore