import { getBizInformations } from "@/api/bizinformation/bizinformation";
import {
    getContractTracking,
    getContractStatus,
    getContractStatusDetails,
    getContractFailed,
    getContractStatisticsCalculate,
    getContractEcmsStatistics,
    getContractStatistics,
    getContractStatisticsDetails,
} from "@/api/contract/contract";

const commonEbaStore = {
    namespaced: true,
    state: {
        bizInformations: null,
        contractTracking: null,
        contractStatus: null,
        contractFailed: null,
        contractStatusDetails: null,
        contractStatisticsCalculate: null,
        contractEcmsStatistics: null,
        contractStatistics: null,
        contractStatisticsDetails: null,
        searchType: {
            bizCode: '',
            dateType: null,
            trackingType: '',
            startDate: null,
            endDate: null,
        },
        pageRequest: {
            page: 1,
            empty: null,
            first: null,
            last: null,
            totalElements: 0,
            totalPages: 0,
        },
    },
    mutations: {
        BIZ_INFORMATIONS(state, payload) {
            state.bizInformations = payload
        },
        CONTRACT_TRACKING(state, payload) {
            state.contractTracking = payload.content
            state.pageRequest.page = payload.number + 1
            state.pageRequest.empty = payload.empty
            state.pageRequest.first = payload.first
            state.pageRequest.last = payload.last
            state.pageRequest.totalElements = payload.totalElements
            state.pageRequest.totalpages = payload.totalPages

            state.searchType.dateType = payload.dateType
        },
        CONTRACT_STATUS(state, payload) {
            state.contractStatus = payload.content
            state.pageRequest.page = payload.number + 1
            state.pageRequest.empty = payload.empty
            state.pageRequest.first = payload.first
            state.pageRequest.last = payload.last
            state.pageRequest.totalElements = payload.totalElements
            state.pageRequest.totalpages = payload.totalPages

            state.searchType.dateType = payload.dateType
        },
        CONTRACT_STATUS_DETAILS(state, payload) {
            state.contractStatusDetails = payload.content
            state.pageRequest.page = payload.number + 1
            state.pageRequest.empty = payload.empty
            state.pageRequest.first = payload.first
            state.pageRequest.last = payload.last
            state.pageRequest.totalElements = payload.totalElements
            state.pageRequest.totalpages = payload.totalPages

            state.searchType.dateType = payload.dateType
        },
        CONTRACT_FAILED(state, payload) {
            state.contractFailed = payload.content
            state.pageRequest.page = payload.number + 1
            state.pageRequest.empty = payload.empty
            state.pageRequest.first = payload.first
            state.pageRequest.last = payload.last
            state.pageRequest.totalElements = payload.totalElements
            state.pageRequest.totalpages = payload.totalPages

            state.searchType.dateType = payload.dateType
        },
        CONTRACT_STATISTICS(state, payload) {
            state.contractStatistics = payload.content
            state.pageRequest.page = payload.number + 1
            state.pageRequest.empty = payload.empty
            state.pageRequest.first = payload.first
            state.pageRequest.last = payload.last
            state.pageRequest.totalElements = payload.totalElements
            state.pageRequest.totalpages = payload.totalPages

            state.searchType.dateType = payload.dateType
        },
        CONTRACT_STATISTICS_DETAILS(state, payload) {
            state.contractStatisticsDetails = payload.content
            state.pageRequest.page = payload.number + 1
            state.pageRequest.empty = payload.empty
            state.pageRequest.first = payload.first
            state.pageRequest.last = payload.last
            state.pageRequest.totalElements = payload.totalElements
            state.pageRequest.totalpages = payload.totalPages

            state.searchType.dateType = payload.dateType
        },
        CONTRACT_STATISTICS_CALCULATE(state, payload) {
            state.contractStatisticsCalculate = payload
            state.searchType.dateType = payload.dateType
        },
        CONTRACT_ECMS_STATISTICS(state, payload) {
            state.contractEcmsStatistics = payload
            state.searchType.dateType = payload.dateType
        },
    },
    actions: {
        async FETCH_BIZ_INFORMATIONS({commit}) {
            const response = await getBizInformations()
            if (response.data.result === true) {
                commit('BIZ_INFORMATIONS', response.data.contents)
            }
            return response
        },
        async FETCH_CONTRACT_TRACKING({commit}) {
            const response = await getContractTracking()
            if (response.data.result === true) {
                commit('CONTRACT_TRACKING', response.data.contents)
            }
            return response
        },
        async FETCH_CONTRACT_STATUS({commit}) {
            const response = await getContractStatus()
            if (response.data.result === true) {
                commit('CONTRACT_STATUS', response.data.contents)
            }
            return response
        },
        async FETCH_CONTRACT_STATUS_DETAILS({commit}) {
            const response = await getContractStatusDetails()
            if (response.data.result === true) {
                commit('CONTRACT_STATUS_DETAILS', response.data.contents)
            }
            return response
        },
        async FETCH_CONTRACT_FAILED({commit}) {
            const response = await getContractFailed()
            if (response.data.result === true) {
                commit('CONTRACT_FAILED', response.data.contents)
            }
            return response
        },
        async FETCH_CONTRACT_STATISTICS({commit}) {
            const response = await getContractStatistics()
            if (response.data.result === true) {
                commit('CONTRACT_STATISTICS', response.data.contents)
            }
            return response
        },
        async FETCH_CONTRACT_STATISTICS_DETAILS({commit}) {
            const response = await getContractStatisticsDetails()
            if (response.data.result === true) {
                commit('CONTRACT_STATISTICS_DETAILS', response.data.contents)
            }
            return response
        },
        async FETCH_CONTRACT_STATISTICS_CALCULATE({commit}) {
            const response = await getContractStatisticsCalculate()
            if (response.data.result === true) {
                commit('CONTRACT_STATISTICS_CALCULATE', response.data.contents)
            }
            return response
        },

        async FETCH_CONTRACT_ECMS_STATISTICS({commit}, payload) {
            const response = await getContractEcmsStatistics(payload)
            if (response.data.result === true) {
                commit("CONTRACT_ECMS_STATISTICS", response.data.contents);
            }
            return response;
        },
    }
}

export default commonEbaStore