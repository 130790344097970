<template>
    <article class="default_table modal">
        <div class="modal_table_title"><slot name="modalTableTitleSocket">default</slot></div>
        <table>
            <thead>
                <tr>
                    <th v-for="(column, index) in columns" :key="index">{{ column.label }}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, rowIndex) in data" :key="rowIndex">
                    <td v-for="(column, colIndex) in columns" :key="colIndex">
                        {{ column.key.includes('Date') ? formatContractDate(row[column.key]) : row[column.key] }}
                    </td>
                </tr>
            </tbody>
        </table>
    </article>
</template>

<script>
export default {
    name: 'ModalTableComp',
    props: {
        columns: {
            type: Array,
            default: () => [],
        },
        data: {
            type: Array,
            default: () => [],
        },
    },
    methods: {
        formatContractDate(value) {
            if (value === null || value === undefined) {
                return ''
            }
            return value.replace('T', ' ');
        },
    },
}
</script>