import Vue from "vue";
import App from "./App.vue";
import router from "@/router";
import { store } from "@/stores";
import GlobalComponent from "@/plugins/GlobalComponents.js";
import mixin from "@/mixins/index.js";
import Pagination from "vue-pagination-2";

Vue.config.productionTip = false;

Vue.mixin(mixin);
Vue.component(Pagination);

new Vue({
    router,
    store,
    components: { GlobalComponent },
    render: h => h(App)
}).$mount("#app");